@use "colors";
@use "button.styles";
@import 'colors.scss';

hr {
    border-bottom: 1px solid #bfbfbf80;
    margin: 0;
    opacity: 0.2;
}

.mat-button {
    font-family: 'Roboto Regular', sans-serif !important;
    text-decoration: underline !important;
    color: $primary !important;
    background: transparent !important;
    padding: 0 !important;
    margin: 0 !important;
    min-width: 0 !important;
}

.mat-raised-button,
.mat-stroked-button,
.mat-flat-button {
    font-family: 'Roboto Condensed', sans-serif !important;
    border-radius: 4px !important;
    box-shadow: none !important;
}

.page {
    margin: 0 16px 0 16px;
}

.page-fullscreen {
    margin: 0;
}

.page-title {
    font-size: 26px;
}

.page-spinner {
    position: fixed !important;
    left: calc(50% - 20px);
    bottom: calc(50% - 20px);
}

.snackbar {
    min-width: 0 !important;
    width: 343px;
    background-color: #474747;
    color: #ffffff;
    box-shadow: rgba(71, 71, 71, 0.35);
    font-family: 'Roboto Regular', sans-serif !important;
    font-size: 16px !important;
    font-style: normal;
    font-weight: 400;
    line-height: 24px !important;
    padding: 16px !important;
}

.mat-simple-snackbar-action {
    margin: -8px 0 -8px 0 !important;
}
.snackbar-error {
    min-width: 0 !important;
    background-color: white;
    color: red;
    font-family: 'Roboto Condensed', sans-serif !important;
}

.icon {
    width: 22px;
    height: 22px;
    padding: 2px;
}

.mat-tab-label-active {
    opacity: 1 !important;
}

@font-face {
    font-family: 'Roboto Regular';
    src: url('../../../assets/Roboto/Roboto-Regular.ttf') format('truetype');
}

@font-face {
    font-family: 'Roboto Condensed';
    src: url('../../../assets/Roboto_Condensed/static/RobotoCondensed-Bold.ttf')
        format('truetype');
}

@font-face {
    font-family: 'Roboto Medium';
    src: url('../../../assets/Roboto/Roboto-Medium.ttf') format('truetype');
}

h6 {
    width: fit-content;
    margin: 0;
    white-space: nowrap;
    text-overflow: clip;
    overflow: hidden;
    display: inline-flex;
    font-family: 'Roboto Condensed', sans-serif;
    font-size: 12px;
}
h5 {
    width: fit-content;
    margin: 0;
    white-space: nowrap;
    text-overflow: clip;
    overflow: hidden;
    display: inline-flex;
    font-family: 'Roboto Condensed', sans-serif;
    font-size: 16px;
}

.custom-hyper-link {
    color: #20a87e !important;
}
